<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="saleInfo"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
          <br />

          <div id="BaseView">
            <section data-class="work-list-table">
              {{ detailInfo.title_std.label }}
              <!-- テーブル -->
              <BaseTable
                select-mode="single"
                :fields="fields"
                :items="tableItems"
              />
            </section>
          </div>
          <br />

          <div id="BaseView">
            <section data-class="work-list-table">
              {{ detailInfo.title_add.label }}
              <!-- テーブル -->
              <BaseTable
                select-mode="single"
                :fields="fieldsAdd"
                :items="tableOpts"
              />
            </section>
          </div>
        </div>
      </template>

      <template v-slot:footer="{ cancel }">
        <!-- 設定ボタン -->
        <!-- <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.setting"
          @click="setting(obj)"
        /> -->
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import SaleInfo from './SaleInfo.js'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import { init } from '@/order/sale/SaleModal.js'

const screenId = SCREEN_ID.SALES_INFO

export default {
  components: {},
  props: {
    selectedItem: {
      type: Object,
    },
    screenKbn: {
      type: Number,
    },
    getDatas: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    getDatas() {
      init(this)
    },
  },

  data() {
    return {
      saleInfo: new SaleInfo(),
      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],
      tableOpts: [],
      obj: this,
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 受注オーダID
              type: 'text',
              id: 'orderId',
              columnInfo: this.detailInfo.order_id,
            },
          ],
        },
      ]
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },
    fieldsAdd() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS + '_add'
      )
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')
      return info
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
  },

  methods: {
    getListOptions,
    init,
    openSubModal,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>

import { convertSnakeCace } from '@/common/Common.js'
import SaleService from './SaleService'
import _ from 'lodash'

export function init(obj) {
  // 当画面 <= 親画面
  let orderId = obj.getDatas.orderId

  // get -> getByOrderId
  obj.$store.dispatch('init/setServiceLoading')
  SaleService.getByOrderId(orderId)
    .then(
      (response) => {
        obj.saleInfo = response.data

        obj.saleInfo.orderId = orderId

        // details
        var details = convertSnakeCace(obj.saleInfo.saleStdDetails)

        // optdetails
        var optdetails = convertSnakeCace(obj.saleInfo.saleOptDetails)

        obj.saleInfo.saleStdDetails = details
        obj.tableItems = details

        obj.saleInfo.saleOptDetails = optdetails
        obj.tableOpts = optdetails

        obj.oriData = _.cloneDeep(obj.saleInfo)
      }
      // (e) => {
      //   // エラーモーダル表示
      //   // var msg
      //   // if (e.response == undefined) {
      //   //   msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
      //   // } else if (e.response.status == 404) {
      //   //   msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
      //   // } else {
      //   //   msg = e.response.data.message + e.response.data.messageDetail
      //   // }
      //   //openMsgModal(obj, MSG_TYPE.E, msg)
      // }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
